import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
// import Img from "gatsby-image";
// import Moment from "react-moment";
// import Markdown from "react-markdown";
import { Helmet } from "react-helmet";
import { isPast, addDays } from "date-fns";
import Navbar from "./navbar";

export const query = graphql`
  query CompetitionQuery($indetity: String!) {
    placeholder: file(relativePath: { eq: "hcs-square.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoSGP: file(relativePath: { eq: "logo-sgp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    logoSEQM: file(relativePath: { eq: "logo-seqm.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    competition: allRestApiV1Competition(filter: { id: { eq: $indetity } }) {
      nodes {
        id
        registerId
        title
        startDate
        endDate
        image
        city
        state
        location
        folder
        manager
        judge
        subscription
        map
        modality
        from
        live
        isMilli
      }
    }
  }
`;

const btnStyle = {
  margin: "0 .3rem",
};

const imageStyle = {
  maxHeight: "75px",
  maxWidth: "75px",
};

const subtitleStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
};

const fromStyle = {
  position: "absolute",
  width: "2.5rem",
  right: "5px",
  top: "5px",
};

const CompetitionDetails = ({ data }) => {
  const comp = data.competition.nodes[0];
  let startDate = comp.isMilli
    ? format(Number(comp.startDate), "MM-dd-yyyy")
    : comp.startDate;
  let endDate = comp.isMilli
    ? format(Number(comp.endDate), "MM-dd-yyyy")
    : comp.endDate;
  startDate = startDate && startDate.includes("undefined") ? null : startDate;
  endDate = endDate && endDate.includes("undefined") ? null : endDate;
  const scheduleStartDate = comp.isMilli
    ? `${format(Number(comp.startDate), "yyyyMM")}${
        parseInt(format(Number(comp.startDate), "dd")) + 1
      }T000000Z`
    : `${comp.startDate.split("-")[2]}${comp.startDate.split("-")[0]}${
        parseInt(comp.startDate.split("-")[1]) + 1
      }T000000Z`;

  const scheduleEndDate = comp.isMilli
    ? `${format(Number(comp.endDate), "yyyyMM")}${
        parseInt(format(Number(comp.endDate), "dd")) + 1
      }T000000Z`
    : `${parseInt(comp.endDate?.split("-")[2]) + 1}${
        comp.endDate?.split("-")[0]
      }${parseInt(comp.endDate?.split("-")[1]) + 1}T000000Z`;
  console.log(startDate);
  const googleCalendar = `https://www.google.com/calendar/event?action=TEMPLATE&text=${
    comp.title
  }&dates=${scheduleStartDate}/${
    scheduleEndDate ? scheduleEndDate : scheduleStartDate
  }&details&location=${comp?.location},${comp?.city} - ${
    comp?.state
  }%2C+Brasil&trp=false&sprop=website:https://competicoesequestre.com.br/competitions/${
    comp.id
  }`;
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta http-equiv="Content-Language" content="pt-br" />
        <title>{comp.title}</title>
        <link
          rel="canonical"
          href={`https://campeonatos.horsecs.com.br/competitions/${comp.id}`}
        />
        <meta
          property="og:url"
          content={`https://campeonatos.horsecs.com.br/competitions/${comp.id}`}
        />
        <meta property="og:title" content={comp.title} />
        <meta
          property="og:description"
          content={`Informações sobre a competição: ${comp.title}`}
        />
        <meta
          property="og:image"
          content={
            comp.image ? comp.image : data.placeholder.childImageSharp.fluid.src
          }
        />
      </Helmet>
      <Navbar />
      <div className="page" style={{ background: "#f0f2f4", height: "100vh" }}>
        <div className="container" style={{ paddingTop: "5rem" }}>
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <p>
                  {comp.title.split(" ").map((x, i) =>
                    i % 5 == 0 && i > 4 ? (
                      <React.Fragment>
                        {x}
                        <br />
                      </React.Fragment>
                    ) : (
                      `${x} `
                    )
                  )}
                </p>
              </li>
            </ul>
          </nav>
          <div className="card">
            <div className="card-content">
              <div className="media">
                <div className="media-center">
                  <img
                    src={
                      comp.image
                        ? comp.image
                        : data.placeholder.childImageSharp.fluid.src
                    }
                    style={imageStyle}
                    className="author-image"
                    alt={`Logo de ${comp.title}`}
                  />
                </div>
                <div className="media-content  has-text-centered">
                  <p className="title is-5">{comp.title}</p>
                  <p className="subtitle is-6" style={subtitleStyle}>
                    {format(new Date(startDate), "dd MMMM yyyy", {
                      locale: ptBR,
                    })}{" "}
                    {endDate
                      ? "até " +
                        format(new Date(endDate), "dd MMMM yyyy", {
                          locale: ptBR,
                        })
                      : null}
                    <a
                      className="button is-info is-small"
                      style={btnStyle}
                      href={googleCalendar}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Adicionar na agenda do google
                    </a>
                  </p>
                </div>
              </div>
              <div className="content">
                <p>
                  {comp.location} - {comp.city}, {comp.state}
                </p>
                <p>
                  {comp.manager ? (
                    <span>
                      <b>Proprietário:</b> {comp.manager}
                    </span>
                  ) : null}
                  <br />
                  {comp.judge ? (
                    <span>
                      <b>Juiz: </b>
                      {comp.judge}
                    </span>
                  ) : null}
                </p>
                <div className="row">
                  {!isPast(
                    addDays(new Date(endDate ? endDate : startDate), 1)
                  ) && comp.live ? (
                    <a
                      className="button is-primary"
                      style={btnStyle}
                      href={comp.live}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ver Ao Vivo
                    </a>
                  ) : null}
                  {comp.map ? (
                    <a
                      className="button is-link"
                      style={btnStyle}
                      href={comp.map}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Mapa
                    </a>
                  ) : null}
                  {comp.folder ? (
                    <a
                      className="button is-success"
                      styles={btnStyle}
                      href={comp.folder}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Folder
                    </a>
                  ) : null}

                  <a
                    className="button is-warning"
                    style={btnStyle}
                    href={
                      comp.subscription
                        ? comp.subscription
                        : comp.from === "seqm"
                        ? "https://seqm.com.br/"
                        : comp.from === "sgp"
                        ? "https://www.sgpsistema.com/"
                        : null
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Inscrições
                  </a>
                </div>
                <div className="from" style={fromStyle}>
                  {comp.from === "seqm" ? (
                    <img
                      src={data.logoSEQM.childImageSharp.fluid.src}
                      alt="Logo SEQM"
                    />
                  ) : null}
                  {comp.from === "sgp" ? (
                    <img
                      src={data.logoSGP.childImageSharp.fluid.src}
                      alt="Logo SGP"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompetitionDetails;
